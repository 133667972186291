/*!
   Created by [Candinya](https://candinya.com)
   Created for [Kratos-Rebirth](https://github.com/Candinya/Kratos-Rebirth)
*/
/******************* Default ********************/
:root {
  --banner-image: url("../images/banner.webp");
  --about-image: url("../images/about.webp");
  --background-image: url("../images/bg.webp");
  --select-text: #fcfcfc;
  --select-text-bg: #262a30;
  --text: #000;
  --text-alt: #666;
  --link: #1e8cdb;
  --link-hover: #6ec3f5;
  --border: #3c495b3a;
  --tag-background: #fffd;
  --card-background: rgba(255, 255, 255, .8);
  --page-background: #f5f5f5;
  --info-background: #efefefaa;
  --navbar-desktop: rgba(40, 42, 44, .6);
  --navbar-mobile: rgba(22, 23, 26, .9);
}

/******************* Theme ********************/
@media (prefers-color-scheme: dark) {
  :root:not([data-user-color-scheme]) {
    --banner-image: url("../images/banner_dark.webp");
    /* --background-image: none; */
    --select-text: #333;
    --select-text-bg: #ccc;
    --text: #eee;
    --text-alt: #ccc;
    --link: lightblue;
    --link-hover: #51aded;
    --border: #ccc;
    --tag-background: #252d38df;
    --card-background: #282c34dd;
    --page-background: #181c27;
    --info-background: #3c495bbb;
    --navbar-desktop: #181c27cc;
  }
}
[data-user-color-scheme=dark] {
  --banner-image: url("../images/banner_dark.webp");
  /* --background-image: none; */
  --select-text: #333;
  --select-text-bg: #ccc;
  --text: #eee;
  --text-alt: #ccc;
  --link: lightblue;
  --link-hover: #51aded;
  --border: #ccc;
  --tag-background: #252d38df;
  --card-background: #282c34dd;
  --page-background: #181c27;
  --info-background: #3c495bbb;
  --navbar-desktop: #181c27cc;
}

/******************* Color ********************/
*::-webkit-selection {
  color: var(--select-text);
  background: var(--select-text-bg);
}
*::-moz-selection {
  color: var(--select-text);
  background: var(--select-text-bg);
}
*::selection {
  color: var(--select-text);
  background: var(--select-text-bg);
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
  color: var(--text);
}

body {
  background-color: var(--page-background);
}

h2.title-h2 {
  background-color: var(--info-background);
}

a {
  color: var(--link);
}

blockquote {
  background-color: var(--info-background);
  border-color: var(--border);
}
blockquote p {
  color: var(--text);
}

.kratos-hentry {
  background-color: var(--tag-background);
}

.kratos-entry-border-new a {
  color: var(--text);
}
.kratos-entry-border-new .kratos-post-meta-new {
  border-top: 1px solid var(--border);
  background: var(--tag-background);
}
.kratos-entry-border-new .kratos-status p a {
  color: var(--link);
}
.kratos-entry-border-new .kratos-status p a:hover {
  color: var(--link-hover);
}

.kratos-entry-header .kratos-post-meta span, .kratos-entry-header .kratos-post-meta a {
  color: var(--text);
}

ul.kratos-post-meta > li {
  color: var(--text-alt);
}

.kratos-post-content p, .kratos-post-content table td {
  color: var(--text);
}

.kratos-post-inner .kratos-copyright {
  background-color: var(--info-background);
}
.kratos-post-inner .kratos-copyright h5 {
  color: var(--text);
}
.kratos-post-inner .kratos-copyright a {
  color: var(--text);
}

.kratos-entry-footer .footer-tag a {
  color: var(--text);
  background-color: var(--tag-background);
}
.kratos-entry-footer .pull-date span {
  color: var(--text);
}

.post-navigation a {
  color: var(--text);
}

#kratos-widget-area .widget a, #kratos-widget-area .widget-title i {
  color: var(--text-alt);
}
#kratos-widget-area .widget.widget-kratos-tags .tag-clouds a {
  background-color: var(--tag-background);
}
#kratos-widget-area .widget.widget-kratos-tags .tag-clouds a:hover {
  color: var(--tag-background);
  background-color: var(--text-alt);
}
#kratos-widget-area .widget.widget-kratos-categories .category-list-item .category-list-count {
  color: var(--text);
  background-color: var(--info-background);
}

.pagination a, .pagination span {
  background-color: var(--tag-background);
  color: var(--text);
}
.pagination > li > a {
  background-color: var(--card-background);
}

.kratos-hentry, .navigation div, #kratos-widget-area .widget {
  background-color: var(--card-background);
}

.arc-tags a {
  color: var(--text-alt) !important;
  background-color: var(--tag-background) !important;
  border-color: var(--border) !important;
}
.arc-tags a:hover {
  color: var(--link-hover) !important;
}

.linkpage ul li a h4 {
  color: var(--text);
}
.linkpage ul li:hover {
  background-color: var(--info-background);
}

.kratos-post-header-title {
  color: var(--text);
}

.xControl {
  background-color: var(--info-background);
}
.xControl .xHeading span {
  color: var(--text);
}

.index-post .kratos-entry-header .kratos-entry-title {
  color: var(--text);
}
.index-post .kratos-entry-header .kratos-entry-title::after {
  background-color: var(--text);
}

#archives .posts-collapse .post-title a {
  color: var(--text-alt);
}

.post-comments {
  background-color: var(--tag-background);
}

a:hover,
.kratos-entry-border-new a:hover,
.kratos-entry-header .kratos-post-meta a:hover,
.kratos-post-inner .kratos-copyright a:hover,
#footer li a:hover,
#archives .posts-collapse .post-title a:hover {
  color: var(--link-hover);
}

#kratos-desktop-topnav {
  background: var(--navbar-desktop);
}

#kratos-mobile-topnav {
  background: var(--navbar-mobile);
}

.toc-item.active > a {
  color: var(--tag-background);
}
.toc-item.active > a::before {
  border-left-color: var(--tag-background);
}

span.blur {
  text-shadow: 0 0 0.5em var(--text);
}
span.blur:hover {
  color: var(--text) !important;
}

/******************* Plugins ********************/
#dsqjs a {
  color: var(--link);
}
#dsqjs .dsqjs-tab-active {
  color: var(--text);
}
#dsqjs-msg {
  color: var(--text);
  background-color: var(--tag-background);
}

.post-comments .v[data-class=v] p,
.post-comments .v[data-class=v] .vheader label,
.post-comments .v[data-class=v] .veditor, .post-comments .v[data-class=v] .veditor::placeholder {
  color: var(--text);
}
.post-comments .v[data-class=v] .vbtn,
.post-comments .v[data-class=v] .vcount,
.post-comments .v[data-class=v] .vcard .vhead span.vnick,
.post-comments .v[data-class=v] .vcard .vhead .vtime {
  color: var(--text-alt);
}
.post-comments .v[data-class=v] .vicon,
.post-comments .v[data-class=v] .vcard .vhead .vreply svg {
  fill: var(--text-alt);
}
.post-comments .v[data-class=v] .vpanel {
  background-color: var(--tag-background);
}
.post-comments .v[data-class=v] .veditor:focus, .post-comments .v[data-class=v] .vinput:focus {
  background-color: var(--info-background);
}

#aplayer * {
  color: unset;
}

span.mjx-chtml.MJXc-display {
  color: var(--text);
}

/******************* Images ********************/
@media (min-width: 768px) {
  body.custom-background {
    background-image: var(--background-image);
  }

  .kratos-cover.kratos-cover-2 {
    background-image: var(--banner-image);
  }

  .widget-kratos-about .photo-background {
    background-image: var(--about-image);
  }
}